.single-post-sidebar {
  display: flex;
  flex-direction: column;
  border: 1px solid $mid-gray-1;
  width: 310px;
  background-color: $white;
  border-radius: 4px;
  max-height: 540px;
  height: fit-content;
  margin-right: 32px;
  margin-left: 12px;
}

.sidebar-height {
  min-height: 300px;
}

.single-post-sidebar-banner {
  position: relative;
  display: flex;
  align-items: center;
  width: 310px;
  height: 34px;
  font-weight: bold;
  background-position: contain;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.about-community {
  font-size: 0.9rem;
  margin-left: 10px;
}

.single-post-sidebar-container {
  margin-left: 10%;
  display: flex;
  align-items: center;
}

.single-post-sidebar-subreddit {
  font-weight: 500;
  font-size: 1rem;
  margin: 10px 15px 5px 5px;
  cursor: pointer;
}

.single-post-sidebar-subreddit-thumbnail {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin: 10px 0 2px -20px;
  cursor: pointer;
}

.single-post-sidebar-description {
  padding-left: 5px;
  margin-top: 10px;
  padding-right: 2px;
  overflow-wrap: break-word;
}

.single-post-sidebar-description,
.single-post-sidebar-description h2 {
  font-family: NotoSans, BentonSans, Verdana, arial, helvetica, sans-serif;
  font-size: 0.89rem;
  line-height: 1.3rem;
  margin-left: 5px;
  font-weight: 400;
}

.single-post-sidebar-info-container {
  width: 80%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 8px;
}

.single-post-sidebar-members-container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 50%;
}

.single-post-sidebar-members {
  font-family: $IBMPlexSans;
  font-weight: 500;
  font-size: 1rem;
}

.single-post-sidebar-members-text {
  font-family: $IBMPlexSans;
  font-size: 0.75rem;
  font-weight: 500;
  padding-top: 1px;
  margin-top: 1px;
}

.single-post-sidebar-online-container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 50%;
}

.single-post-sidebar-online {
  font-weight: 500;
  font-size: 1rem;
  font-family: $IBMPlexSans;
  margin-left: 2px;
}

.single-post-sidebar-online-text {
  font-family: $IBMPlexSans;
  font-size: 0.75rem;
  font-weight: 500;
  padding-top: 1px;
  margin-left: 2px;
  margin-top: 1px;
}

.single-post-sidebar-hr {
  border-bottom: 1px solid $light-gray-8;
  width: 80%;
  margin-left: 10%;
}

.single-post-side-bar-created {
  margin: auto 0;
}

.single-post-sidebar-cake {
  width: 20px;
  height: 20px;
  margin-left: 12px;
  margin-right: 5px;
}

.single-post-sidebar-created-container {
  display: flex;
  margin-top: 20px;
}

.single-post-sidebar-all-communities {
  display: block;
  min-width: 32px;
  width: 280px;
  height: 24px;
  min-height: 32px;
  line-height: 17px;
  font-size: 14px;
  padding: 4px 16px;
  margin: 20px auto;
  cursor: pointer;
}

.subhome-sidebar-margin {
  margin-top: 20px;
  margin-left: 25px;
}
