.sidebar-premium-container {
  margin-left: 24px;
  border: 1px solid $mid-gray-1;
  width: 310px;
  /* width: 340px; */
  margin-top: 16px;
  background-color: white;
  border-radius: 4px;
  height: 106px;
}

.sidebar-premium-content-container {
  display: flex;
  padding: 10px;
  align-items: center;
}

.premium-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.premium-title {
  font-size: 0.8rem;
  font-weight: 500;
}

.premium-text {
  font-size: 0.75rem;
  background-color: white;
}

.btn-try-now-container {
  display: flex;
  justify-content: center;
}

.btn-try-now {
  width: 280px;
  height: 24px;
  padding: 16px 4px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
