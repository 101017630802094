.comment-container {
  align-items: center;
  width: 100%;
}

.comment-container li {
  list-style-type: none;
  padding-left: 20px;
  margin-left: -20px;
  padding-left: 5px;
  padding-top: 10px;
}

li, li p, li h1, li h2 {
  font-size: 0.9rem;
  font-weight: 500;
  font-family: NotoSans, BentonSans, Verdana, arial, helvetica, sans-serif;
}

.comment-border {
  border-left: 2px solid $light-gray-8;
  margin-left: -5px;
  padding-left: 5px;
  position: relative;
  top: 10px;
}

.base-comment {
  position: relative;
  display: block;
  flex-direction: column;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.subcomment {
  border-left: 2px solid $light-gray-8;
  /* hover 68, 78, 89 */
}

.comment-details-container {
  display: flex;
  margin-bottom: -5px;
}

.comment-details {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.comment-author {
  font-size: 0.75rem;
  color: $dark-gray-3;
  font-weight: 500;
  font-family: $IBMPlexSans;
  margin-left: -5px;
}

.comment-separator-dot {
  position: relative;
  bottom: 3px;
  margin: 0px 5px;
}

.comment-time-posted {
  font-size: 0.75rem;
  color: $mid-gray-3;
}

.comment-avatar {
  height: 36px;
  width: 36px;
  margin-left: -22px;
  margin-right: 10px;
  margin-top: -10px;
  border-radius: 50%;
}

.comment-body {
  padding-bottom: 20px;
  padding-left: 18px;
  padding-top: 5px;
  padding-right: 10px;
}

.comment-bottom-bar {
  display: flex;
  position: relative;
  margin-left: 18px;
  margin-top: -10px;
}

.comment-votes {
  display: flex;
}

.comment-votes-up, .comment-votes-down {
  height: 18px;
  width: 18px;
  margin-right: 5px;
  margin-top: 8px;
}

.comment-votes-count {
  font-size: 0.73rem;
  font-weight: bold;
  font-family: $IBMPlexSans;
  margin-right: 5px;
  position: relative;
  top: 8px;
}

.comment-reply-container {
  display: flex;
}

.comment-speechbubble {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 8px;
}

.comment-link {
  color: $mid-gray-4;
  font-size: 0.75rem;
  font-family: $IBMPlexSans;
  padding: 8px 7px 3px 7px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: $low-opacity-gray;
  }
}
