.sidebar-container {
  display: flex;
  flex-direction: column;
}

.sidebar {
  border: 1px solid $mid-gray-1;
  width: 310px;
  background-color: $white;
  border-radius: 4px;
  min-height: 400px;
  max-height: 450px;
  margin-top: 28px;
  margin-left: 24px;
}

.top-community {
  width: 310px;
  height: 80px;
  color: $white;
  font-weight: bold;
  background-image: url("../assets/images/banner-background.png");
  background-position: contain;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
}

.top-community::before {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, $very-low-opacity-gray 0%, $mid-opacity-gray 100%);
  position: absolute;
  content: '';
}

.top-community-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  bottom: 8px;
  color: $white;
  left: 16px;
  position: absolute;
  font-weight: bold;
  font-family: BentonSans;
  letter-spacing: 0.5px;
}

.community {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-gray-4;
  width: 295px;
  height: 48px;
  padding-left: 15px;
  cursor: pointer;
  font-size: 0.9rem;
}

.sidebar-subreddit-name {
  font-weight: 500;
  padding-left: 10px;
}

.up-v {
  position: relative;
  width: 11px;
  height: 6px;
  left: 10px;
  margin: 0 5px;
}

.sidebar-subreddit-logo {
  height: 32px;
  width: 32px;
  margin-left: 15px;
}

.community span {
  font-weight: 500;
}

.view-all-communities {
  padding: 4px 16px;
  width: 280px;
  margin: 0 auto;
  margin-top: 12px;
  display: block;
  height: 24px;
  line-height: 17px;
  font-size: 14px;
  min-height: 32px;
  min-width: 32px;
  cursor: pointer;
}

.small-btns {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-community-small {
  color: $blue;
  background-color: $light-gray-1;
  border-radius: 100vmax;
  font-weight: bold;
  padding: 5px;
  font-size: 0.75rem;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-7;
  }
}
