@font-face {
  font-family: NotoSans;
  src: url(../fonts/NotoSans-Regular.ttf);
}

.post-container {
  width: 640px;
  min-height: 1000px;
}

.post {
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid $mid-gray-1;
  display: flex;
  position: relative;
}

.link-post {
  min-height: 170px;
}

.post-votes {
  background-color: $light-gray-1;
  align-items: center;
  min-width: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 10px;
}

.post-votes-arrow {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.post-votes-count {
  font-weight: bold;
  font-size: 0.73rem;
  padding: 5px 0 9px 0;
}

.post-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 2px 0;
}

.post-top {
  width: 595px;
  height: 35px;
  padding-top: 2px;
  padding-left: 8px;
}

.subreddit-thumbnail {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 5px;
  top: 5px;
}

.post-subreddit {
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.post-posted-by {
  color: $mid-gray-3;
  font-size: 0.7rem;
  cursor: pointer;
}

.separator-dot {
  font-size: 8px;
  color: $mid-gray-3;
  padding-left: 5px;
  padding-right: 5px;
}

.post-awards {
  display: inline-flex;
  flex-wrap: wrap;
  font-size: 0.7rem;
  color: $mid-gray-3;
  cursor: pointer;
  margin: 0 80px 0 5px;
}

.post-award {
  height: 16px;
  width: 16px;
  line-height: 16px;
  vertical-align: middle;
  margin: 0 3px;
}

.post-title {
  font-family: $IBMPlexSans;
  font-size: 1.1rem;
  font-weight: 500;
  color: $dark-gray-2;
  word-break: break-word;
  cursor: pointer;
  margin: 4px 40px 10px 8px;
}

.post-title-shortened {
  width: calc(100% - 175px);
  margin: 4px 0 10px 8px;
}

.flair {
  display: inline-flex;
  font-size: 0.7rem;
  font-weight: 500;
  border-radius: 20px;
  padding: 2px 8px;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
  line-height: 16px;
  width: fit-content;
}

.flair-image {
  height: 16px;
  width: 16px;
  vertical-align: bottom;
}

.flair-oc {
  background-color: $blue;
  color: white;
  border-radius: 4px;
  font-size: 0.75rem;
  padding: 1px 3px;
  margin-left: 5px;
  line-height: 0.75rem;
}

.post-link {
  position: relative;
  display: block;
  font-size: 0.8rem;
  padding-left: 8px;
  margin-top: -5px;
  margin-bottom: 5px;
}

.link-image {
  position: absolute;
  display: block;
  width: 144px;
  height: 100px;
  border-radius: 4px;
  border: 1px solid $blue;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  margin: 6px 0;
  cursor: pointer;
  object-fit: cover;
  margin-left: auto;
}

.video-background {
  background-color: $black;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
}

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.post-image {
  position: relative;
  max-width: 100%;
  max-height: 510px;
  padding-bottom: 4px;
  cursor: pointer;
}

.post-gallery-image {
  max-width: 100%;
  cursor: pointer;
  max-height: 510px;
  position: relative;
}

.gallery-button {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  font-size: 3rem;
  line-height: 5px;
  outline: none;
  border: none;
  background-color: white;
  color: $mid-gray-2;
  cursor: pointer;
}

.gallery-button span {
  position: relative;
  top: -3px;
}

.gallery-button-left {
  position: absolute;
  left: 45px;
  z-index: 2;
}

.gallery-button-right {
  position: absolute;
  right: 5px;
}

.post-video {
  position: relative;
  height: 500px;
  max-width: 100%;
  object-fit: contain;
}

.spoiler {
  background: linear-gradient($mid-gray-1, $mid-gray-2, $mid-gray-3);
  outline: none;
  border: none;
}

.post-bottom-bar {
  position: relative;
  display: flex;
  margin-top: auto;
  bottom: 0;
  font-size: 0.85rem;
  height: 35px;
  width: 70%;
}

.post-comments {
  display: flex;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: $light-gray-3;
    border-radius: 5px;
  }
}

.comments-speechbubble {
  position: relative;
  height: 20px;
}

.comments {
  position: relative;
  font-size: 0.75rem;
  color: $mid-gray-4;
  left: 5px;
}

.post-share-link {
  display: flex;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: $light-gray-3;
    border-radius: 5px;
  }
}

.share-arrow {
  position: relative;
  height: 21px;
  bottom: 4px;
}

.share {
  position: relative;
  color: $mid-gray-4;
  font-size: 0.75rem;
  top: 1px;
}

.post-save {
  display: flex;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: $light-gray-3;
    border-radius: 5px;
  }
}

.save-banner {
  position: relative;
  height: 18px;
  width: 14px;
}

.save {
  position: relative;
  color: $mid-gray-4;
  font-size: 0.75rem;
  left: 5px;
}

.post-more {
  color: $mid-gray-4;
  font-size: 1.3rem;
  cursor: pointer;
  height: 50%;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding-bottom: 10px;

  &:hover {
    background-color: $light-gray-3;
    border-radius: 5px;
  }
}
