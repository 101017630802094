.btn {
  font-family: $NotoSans;
  border-radius: 100vmax;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
}

.btn-blue {
  color: $white;
  background-color: $blue;
  border: 1px solid $blue;

  &:hover {
    background-color: $dark-blue;
  }
}

.btn-white {
  color: $blue;
  background-color: $white;
  border: 1px solid $blue;

  &:hover {
    background-color: $light-gray-1;
  }
}

.btn-red {
  color: $white;
  background-color: $red;
  border: none;

  &:hover {
    background-color: $light-red;
  }
}

.btn-join {
  position: absolute;
  font-family: NotoSans, BentonSans, Verdana, arial, helvetica, sans-serif;
  border: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  height: 24px;
  min-width: 24px;
  width: 59px;
  cursor: pointer;
  top: 8px;
  right: 8px;
}