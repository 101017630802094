.single-post-page {
  display: flex;
  width: 100vw;
  /* this height was working fine */
  height: calc(100vh - 48px);
  overflow-y: scroll;
  top: 48px;
  background-color: $dark-gray-1;
  scrollbar-color: rgb(122, 122, 124) rgb(38, 38, 40);
  justify-content: center;
  position: relative;
  z-index: 2;
}

.singlepost-close {
  position: fixed;
  max-width: 1280px;
  /* no idea why these 2 siblings are not the same width when I use width: calc(100% - 160px) for both - at lower than 1465px, both .singlepost-close and .singlepost-container use -160px but above this, they are the same width when .singlepost-close is -176px and the .singlepost-container is -160px */
  width: calc(100% - 176px);
  height: 48px;
  background-color: black;
  /* must keep z-index at 1 or the search is obstructed */
  z-index: 1;
  // top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.top-bar-votes-container {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.top-bar-votes-container::before {
  border-right: 1px solid $dark-gray-4;
  content: "";
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 10px;
}

.top-bar-votes-container::after {
  border-right: 1px solid $dark-gray-4;
  content: "";
  height: 16px;
  vertical-align: text-bottom;
  margin-left: 10px;
  margin-right: 10px;
}

.top-bar-up-arrow {
  height: 20px;
  width: 18px;
  padding-right: 5px;
}

.top-bar-votes {
  color: $light-gray-6;
  font-weight: 700;
  font-size: 0.8rem;
}

.top-bar-down-arrow {
  height: 20px;
  width: 18px;
  padding-left: 5px;
}

.top-bar-title-container {
  min-width: 50%;
  display: flex;
}

.top-bar-title {
  color: $light-gray-6;
  font-size: 0.9rem;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: break-word;
}

.singlepost-flair {
  font-size: 0.75rem;
  border-radius: 20px;
  padding: 2px 8px;
  margin-left: 5px;
  align-self: center;
  font-weight: 500;
  white-space: nowrap;
  display: inline-flex;
}

.top-bar-btn-container {
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 300px;
  font:inherit;
  align-items: center;
}

.top-bar-btn-pointer {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
}

.btn-x {
  cursor: pointer;
  width: 13px;
  height: 13px;
  margin-right: 5px;
}

.top-bar-close {
  cursor: pointer;
  font-family: $NotoSans;
  background-color: black;
  border: none;
  outline: none;
  color: $light-gray-6;
  font-size: 0.7rem;
  font-weight: 700;
}

.singlepost-container {
  box-sizing: border-box;
  background-color: $light-gray-3;
  max-width: 1280px;
  width: calc(100% - 160px);
  display: flex;
  position: relative;
  margin-top: 48px;
  padding-top: 32px;
  justify-content: center;
  height: fit-content;
}

.singlepost-post {
  max-width: 840px;
  min-width: 550px;
  background-color: $white;
  min-height: 300px;
  display: flex;
  border-radius: 4px;
  border: 1px solid $light-gray-8;
  margin-left: 32px;
}

.singlepost-right {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 700px;
}

.singlepost-top {
  padding-left: 8px;
  width: 100%;
  padding-top: 1px;
  display: inline;
  /* white-space: nowrap; */
  flex-wrap: wrap;
  align-items: center;
}

.singlepost-subreddit {
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
}

.singlepost-posted-by {
  color: $mid-gray-3;
  font-size: 0.75rem;
  cursor: pointer;
  display: inline-flex;
}

.singlepost-separator-dot {
  font-size: 8px;
  line-height: 20px;
  color: $mid-gray-3;
  padding-left: 5px;
  padding-right: 5px;
}

.singlepost-awards {
  font-size: 0.7rem;
  padding-left: 10px;
  padding-right: 10px;
  color: $mid-gray-3;
  cursor: pointer;
}

.singlepost-title {
  padding-left: 8px;
  font-size: 1.25rem;
  padding-top: 5px;
  margin-bottom: 10px;
  width: calc(100% - 30px);
  font-weight: 500;
}

.singlepost-title-shortened {
  /* padding-right: 170px; */
  padding-left: 8px;
  font-size: 1.25rem;
  padding-top: 5px;
  margin-bottom: 10px;
  font-weight: 500;
}

.singlepost-link-container {
  margin-top: -10px;
  padding-left: 8px;
}

.singlepost-link {
  font-size: 0.8rem;
  position: relative;
  top: -15px;
}

.singlepost-link-long {
  font-size: 0.8rem;
  position: relative;
}

.singlepost-content-container {
  display: flex;
}

.singlepost-link-image {
  width: 140px;
  min-width: 140px;
  height: 110px;
  border-radius: 4px;
  border: 1px solid $blue;
  cursor: pointer;
  margin: 5px 10px auto 10px;
}

.singlepost-video-background {
  background-color: black;
  justify-content: center;
  display: flex;
  max-width: 100%;
  position: relative;
}

.singlepost-img-center {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.singlepost-image {
  max-width: 100%;
  cursor: pointer;
  max-height: 700px;
  display: flex;
  /* max-width: 700px */
  /* width: 100%; */
  position: relative;
}

.singlepost-video {
  position: relative;
  max-height: 580px;
  max-width: 600px;
  width: 100%;
  cursor: pointer;
}

.singlepost-gallery-btn-container {
  position: relative;
}

.singlepost-gallery-button {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  font-size: 3rem;
  line-height: 5px;
  outline: none;
  border: none;
  background-color: white;
  color: $mid-gray-2;
  cursor: pointer;
}

.singlepost-gallery-button span {
  position: relative;
  top: -3px;
}

.singlepost-gallery-button-left {
  position: absolute;
  left: 10px;
  z-index: 2;
  bottom: -22px;
}

.singlepost-gallery-button-right {
  position: absolute;
  right: 10px;
  bottom: -22px;
}

/* start of styles the comments bar at the bottom */
.singlepost-bottom-bar {
  display: flex;
  /* margin-top: auto; */
  margin-top: 20px;
  font-size: 0.85rem;
  height: 35px;
  width: 70%;
  min-width: 422px;
}

.singlepost-comments {
  display: flex;
  cursor: pointer;
  padding: 10px;
  position: relative;
  font-size: 0.75rem;
  color: $mid-gray-4;

  &:hover {
    background-color: $ultra-low-opacity-gray;
    border-radius: 5px;
  }
}

.singlepost-comments-text {
  position: relative;
  left: 8px;
}

.singlepost-comments-speechbubble {
  position: relative;
  height: 25px;
  bottom: 3px;
}

.singlepost-share-link {
  display: flex;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: $ultra-low-opacity-gray;
    border-radius: 5px;
  }
}

.singlepost-share-arrow {
  position: relative;
  height: 28px;
  bottom: 8px;
}

.singlepost-share-text {
  position: relative;
  color: $mid-gray-4;
  font-size: 0.75rem;
  top: 1px;
}

.singlepost-save {
  display: flex;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: $ultra-low-opacity-gray;
    border-radius: 5px;
  }
}

.singlepost-save-banner {
  position: relative;
  height: 22px;
  width: 17px;
  bottom: 2px;
}

.singlepost-save-text {
  position: relative;
  color: $mid-gray-4;
  font-size: 0.75rem;
  left: 5px;
}

.singlepost-hide {
  display: flex;
  cursor: pointer;
  padding: 10px;
  
  &:hover {
    background-color: $ultra-low-opacity-gray;
    border-radius: 5px;
  }
}

.singlepost-hide-banner {
  width: 20px;
  height: 20px;
}

.singlepost-report {
  display: flex;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: $ultra-low-opacity-gray;
    border-radius: 5px;
  }
}

.singlepost-report-banner {
  width: 15px;
  height: 18px;
}

.signup-bar {
  border: 1px solid $light-gray-8;
  margin-top: 20px;
  margin-right: 30px;
  margin-left: 8px;
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  justify-content: space-between;
}

.signup-text {
  color: $mid-gray-3;
  font-weight: 600;
  margin: auto 0;
}

.post-btn-login {
  margin-right: 10px;
  width: 77px;
  min-height: 32px;
  min-width: 32px;
  font-size: 14px;
  line-height: 14px;

  &:hover {
    background-color: $light-gray-1;
  }
}

.post-btn-signup {
  width: 85px;
  min-height: 32px;
  min-width: 32px;
  font-size: 14px;
  line-height: 14px;

  &:hover {
    background-color: $dark-blue;
  }
}

.singlepost-comment-container {
  margin-top: 50px;
  width: calc(100% + 40px);
  margin-left: -40px;
  padding: 0 0 32px 0;
}

.after-comment-box {
  height: 32px;
  width: calc(100% + 40px);
  margin-left: -40px;
  background-color: $light-gray-3;
}
