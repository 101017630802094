.sort-posts {
  display: flex;
  height: 60px;
  margin-bottom: 16px;
}

.popular-title {
  font-size: 0.85rem;
  font-weight: 500;
}

.popular-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid $mid-gray-1;
  padding: 0 6px 0 14px;
  width: 640px;
}

.hot-icon {
  height: 22px;
  position: relative;
  top: 3px;
  right: 3px;
}

.hot-grey {
  background: url("../assets/images/hot.png");
}

.hot-blue {
  background: url("../assets/images/hot-blue.png");
}

.popular-hot {
  color: $mid-gray-2;
  background-color: $light-gray-1;
  border-radius: 100vmax;
  font-weight: bold;
  font-size: 1rem;
  padding: 1px 9px 7px 9px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-2;
  }
}

.popular-location {
  color: $blue;
  background-color: $light-gray-1;
  border-radius: 100vmax;
  font-weight: bold;
  font-size: 1rem;
  padding: 6px 17px;
  margin-right: 10px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-2;
  }
}

.v-location {
  height: 6px;
  position: relative;
  left: 5px;
  bottom: 1px;
  align-self: center;
}

.new-icon {
  position: relative;
  height: 20px;
  width: 20px;
  top: 4px;
  right: 2px;
}

.new-grey {
  background: url("../assets/images/star.png");
}

.new-blue {
  background: url("../assets/images/star-blue.png");
}

.popular-new {
  position: relative;
  color: $mid-gray-2;
  font-size: 1rem;
  font-weight: bold;
  right: 2px;
  padding: 3px 8px 6px 7px;
  cursor: pointer;
  border-radius: 20px;

  &:hover {
    background-color: $light-gray-2;
  }
}

.top-icon {
  position: relative;
  height: 22px;
  top: 5px;
  right: 3px;
}

.top-grey {
  background: url("../assets/images/top.png");
}

.top-blue {
  background: url("../assets/images/top-blue.png");
}

.hot-icon,
.new-icon,
.top-icon {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  outline: none;
  border: none;
}

.popular-top {
  position: relative;
  color: $mid-gray-2;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 18px;
  padding: 0 8px 6px 10px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-2;
  }
}

.popular-today {
  position: relative;
  display: flex;
  color: $blue;
  font-size: 1rem;
  font-weight: bold;
  right: 10px;
  left: 4px;
  padding: 5px 0px;
  cursor: pointer;
}

.popular-more {
  position: relative;
  display: flex;
  justify-content: center;
  left: 9px;
  color: $mid-gray-2;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 15px;
  height: 25px;
  width: 25px;
  padding: 3px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-2;
    border-radius: 50%;
  }
}

.popular-view {
  position: relative;
  margin-left: auto;
}

.rectangle-icon {
  min-width: 25px;
  height: 18px;
  position: relative;
  top: 3px;
  cursor: pointer;
  // padding: 6px 10px 6px 13px;
  margin-right: 6px;

  &:hover {
    background-color: $light-gray-1;
    border-radius: 100vmax;
  }
}

.dropdown-location-container {
  display: none;
  position: absolute;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 132px;
  margin-left: -10px;
  margin-top: 6px;
  z-index: 5;
}

.popular-location:active + .dropdown-location-container {
  display: block;
}

.show {
  display: block;
}

.dropdown-location {
    position: relative;
    display: block;
    background-color: $white;
    color: $mid-gray-2;
    width: 115px;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 7px 0px 7px 5px;
    text-decoration: none;
    z-index: 5;
    cursor: pointer;

    &:hover {
      background-color: $light-gray-2;
      color: black;
    }
}

.dropdown-top-container {
  display: flex;
  position: absolute;
  z-index: 5;
  margin-left: -10px;
  max-height: 50px;
  overflow: hidden;
  min-width: 400px;
  background-color: $white;
  top: 30px;
  border-radius: 4px;
  border: 1px solid $light-gray-8;
  box-shadow: 0 2px 4px 0 $very-low-opacity-gray;
}

.popular-today:active + .dropdown-top-container {
  display: block;
}

.dropdown-top {
    position: relative;
    background-color: $white;
    color: $mid-gray-2;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 8px 10px;
    text-decoration: none;
    white-space: nowrap;
    z-index: 5;
    cursor: pointer;

    &:hover {
      background-color: $light-gray-2;
      color: black;
    }
}

.clicked, .clicked:hover {
  color: $blue;
  background-color: $light-gray-2;
}
