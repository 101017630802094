@font-face {
  font-family: NotoSansBold;
  src: url(../fonts/NotoSans-Bold.ttf);
}

.navbar {
  display: flex;
  border-bottom: 1px solid $light-gray-8;
  background-color: $white;
  height: 48px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  border: none;
}

.logo {
  position: relative;
  height: 33px;
  min-width: 129px;
  background-image: url("../assets/images/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  top: 7px;
  margin: 0 10px 0 20px;
}

.searchbar-container {
  width: 100%;
  max-width: 700px;
}

.searchbar-subreddits {
  position: relative;
  font-family: inherit;
  font-size: 0.9rem;
  width: 93%;
  height: 36px;
  border-radius: 4px;
  outline: none;
  border: 1px solid $light-gray-2;
  line-height: 14px;
  background-color: $light-gray-1;
  color: $dark-gray-3;
  margin-top: 6px;
  padding: 1px 0 0 0;
  z-index: 5;

  &:focus,
  &:hover {
    border-color: $blue;
  }
}

.search-icon {
  position: relative;
  height: 22px;
  width: 22px;
  left: 36px;
  top: 6px;
  z-index: 7;
}

.current-subreddit {
  position: relative;
  background-color: $light-gray-5;
  left: 65px;
  width: fit-content;
  padding: 3px 4px;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: $IBMPlexSans;
  bottom: 29px;
  border-radius: 15px;
  z-index: 7;
}

.dropdown-subreddits {
  margin-left: 21px;
  margin-top: 6px;
  position: relative;
  background-color: $light-gray-1;
  width: 655px;
  box-shadow: 0px 8px 16px 0px $very-low-opacity-gray;
  z-index: 25;
}

.dropdown-div {
  position: relative;
  cursor: pointer;
  color: black;
  background-color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  z-index: 25;
}

.dropdown-div-hover {
  background-color: $light-gray-2;
}

.btn-container {
  position: relative;
  margin-right: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.btn-login, .btn-signup {
  width: 120px;
  height: 32px;
  padding: 4px 16px 5px 16px;
  font-weight: 700;
  line-height: 17px;
  font-size: 14px;
}

.btn-signup {
  margin-left: 16px;
}

.user-icons-container {
  display: block;
  margin-left: 10px;
  margin-right: 15px;
  width: 70px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border: 1px solid $light-gray-8;
  }
}

.user-icon {
  height: 21px;
  margin: 4px 0 0 15px;
}

.v-icon {
  position: relative;
  height: 6px;
  bottom: 6px;
  margin-left: 3px;
}

.hamburger-container {
  display: none;
  position: relative;
  margin: auto;
}

.navbar-hamburger {
  font-size: 20px;
  width: 25px;
  height: 20px;
  cursor: pointer;
  margin: auto 12px auto 0;
}

.hamburger-options-container {
  position: absolute;
  width: 150px;
  border-radius: 5px;
  background-color: $light-gray-1;
  box-shadow: 0px 8px 16px 0px $very-low-opacity-gray;
  z-index: 25;
  top: 36px;
  right: 5px;
}

.hamburger-option {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-2;
  }

  &:nth-child(1) {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &:nth-child(2) {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
