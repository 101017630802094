.sidebar-links {
  border: 1px solid $mid-gray-1;
  width: 310px;
  /* width: 340px; */
  margin-top: 16px;
  background-color: white;
  border-radius: 4px;
  height: 262px;
  position: sticky;
  top: 58px;
}

.sidebar-links-container {
  display: flex;
  padding: 15px 0;
}

.sidebar-links-left {
  width: 48%;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.sidebar-links-right {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.sidebar-links-left a {
  font-weight: 300;
  font-size: 0.75rem;
  font-family: Noto Sans,Arial,sans-serif;
  padding-bottom: 6px;
  text-decoration: none;
  color: black;

  &:hover{
    text-decoration: underline;
  }
}

.sidebar-links-right a {
  font-weight: 300;
  font-size: 0.75rem;
  font-family: Noto Sans,Arial,sans-serif;
  padding-bottom: 6px;
  text-decoration: none;
  color: black;

  &:hover{
    text-decoration: underline;
  }
}

.sidebar-copyright {
  position: relative;
  display: inline;
  font-weight: 300;
  font-size: 0.75rem;
  font-family: Noto Sans,Arial,sans-serif;
  padding: 12px;
}
